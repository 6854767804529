<template>
    <div class="big-data-page">
        <div class="banner-wrap">
            <div class="banner">
                <div class="banner-box">
                    <div class="banner-text">
                        <div class="title animate__animated animate__fadeInLeftBig">
                            科创大数据
                        </div>
                        <div class="sub-title animate__animated animate__fadeInRightBig">
                            <p>
                                以科技企业为核心，建立机构、人才、技术、区域、产业五大主题域
                            </p>
                            <p>
                                构建起人才链、技术链、产业链、创新链四大关系链
                            </p>
                            <p>
                                形成“五域-四链“的科技企业成长基因库和服务体系
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section class="interface-service animate__animated animate__fadeIn">
            <h1 class="title">数据接口服务</h1>
            <div class="content">
                <div class="card-main">
                    <div class="card-item hover_effect" v-for="(item, index) in apiService" :key="index">
                        <div class="card-item-title">
                            {{ item.title }}
                        </div>
                        <div class="introduce-main">
                            <div :class="`introduce-item ${its.isFullRow ? 'full-row' : ''}`"
                                v-for="(its, idx) in item.list" :key="idx">
                                <p v-html="its.text"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="data-service animate__animated animate__fadeIn">
            <h1 class="title">离线数据服务</h1>
            <div class="content">
                <div class="card-main">
                    <div class="card-item hover_effect" :style="`background-image:url('${item.bgImage}')`"
                        v-for="(item, index) in offlineData" :key="index">
                        <p class="card-item-title">{{ item.title }}</p>
                        <div class="introduce-main">
                            <p class="introduce-item" v-for="(its, idx) in item.introduceList" :key="idx">{{ its }}</p>
                        </div>
                    </div>
                </div>
                <a href="#consultingService" class="custom-my-btn mt44">立即咨询</a>
            </div>
        </section>
        <section class="application-scenarios animate__animated animate__fadeIn">
            <h1 class="title">应用场景</h1>
            <div class="content">
                <div class="card-main">
                    <div class="card-item hover_effect" :style="`background-image: url('${item.bgImage}')`"
                        v-for="(item, index) in applicationData" :key="index">
                        <div class="card-item-title">
                            {{ item.title }}
                        </div>
                        <div class="introduce-main">
                            <div class="introduce-item" v-for="(its, idx) in item.introduceList" :key="idx">
                                <div class="introduce-item-title">{{ its.title }}</div>
                                <p v-for="(it, id) in its.list" :key="id">
                                    {{ it }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="#consultingService" class="custom-my-btn mt44">立即咨询</a>
            </div>
        </section>
        <trial id="consultingService" class="trial" title="数据服务咨询" sceneCode="5" />
    </div>
</template>

<script>
import trial from '@/components/trial.vue'
export default {
    name: 'bigDataPage',
    components: { trial },
    data() {
        return {
            //离线数据
            offlineData: [
                {
                    title: '应用场景',
                    introduceList: ['为无法直接外网的系统定期提供数据', '支撑批量导出特定场景建模数据', '支持项目线下数据统计分析、复盘'],
                    bgImage: 'https://images.zzt.com.cn/gnW/2024/04/24/application-scenarios-bg.png'
                }, {
                    title: '柔性交付',
                    introduceList: ['依据业务需求，可按周、月等周期更新数据', '可通过SQL、CSV、XLS等格式交付', '可通过SFTP、定向邮件等方式加密传输'],
                    bgImage: 'https://images.zzt.com.cn/gnW/2024/04/24/flexible-delivery-bg.png'
                }, {
                    title: '灵活计费',
                    introduceList: ['依据业务需求确定数据范围、规模', '综合评估更新频率、交付形式', '支持单次、周期服务收费'],
                    bgImage: 'https://images.zzt.com.cn/gnW/2024/04/24/flexible-billing-bg.png'
                }
            ],
            //应用场景
            applicationData: [
                {
                    title: '科创企业评估',
                    bgImage: 'https://images.zzt.com.cn/gnW/2024/04/29/case-card-bg1.png',
                    introduceList: [
                        {
                            title: '场景难点',
                            list: ['科创企业投入大、周期长、轻资产、无抵押不符合传统信贷模式', '银行缺少量化数据评价企业的科技能力', '企业线索维度杂乱繁多，难以快速圈定目标科创企业群体']
                        }, {
                            title: '解决方案',
                            list: ['提供科技力评分助力快速量化研判企业的科技实力', '支撑科创金融产品准入指标数据', '输出信贷审批中的科创维度数据']
                        }, {
                            title: '场景热门接口',
                            list: ['科技资质 |  科技力评分 | 专利价值评估']
                        }
                    ]
                },
                {
                    title: '科创金融产品开发',
                    bgImage: 'https://images.zzt.com.cn/gnW/2024/04/29/case-card-bg2.png',
                    introduceList: [
                        {
                            title: '场景难点',
                            list: ['缺乏企业的科技成果质量、技术水平等判断的专业背景', '科技企业画像维度众多，难以快速基于业务确定准入、评价指标']
                        }, {
                            title: '解决方案',
                            list: ['基于银行的展业目标、规模推荐数据指标', '快速批量输出数据协助银行构建及丰富评估授信模型', '支撑银行内部企业评价系统的搭建与迭代']
                        }, {
                            title: '场景热门接口',
                            list: ['产业链 | 科技力评分 | 专利统计 | 科技资质 | 投融资']
                        }
                    ]
                }
            ],
            //数据接口服务
            apiService: [
                {
                    title: '机构',
                    list: [{
                        text: '200万+</br>科技企业'
                    }, {
                        text: '5000+</br>高校科研</br>机构'
                    }, {
                        text: '30+</br>科技资质</br>标签'
                    }, {
                        text: '60万+</br>投融资'
                    }]
                }, {
                    title: '产业',
                    list: [{
                        text: '50+</br>特色产业'
                    }, {
                        text: '300+</br>产业图谱'
                    }, {
                        text: '50,000+</br>产业政策'
                    }, {
                        text: '500万+</br>链属企业'
                    }]
                }, {
                    title: '技术',
                    list: [{
                        text: '50亿+</br>专利'
                    }, {
                        text: '26,000+</br>技术标准'
                    }, {
                        text: '1.6亿+</br>专利评价'
                    }, {
                        text: '75000+</br>技术领域'
                    }]
                }, {
                    title: '人才',
                    list: [{
                        text: '20000万+</br>科技人才'
                    }, {
                        text: '30+</br>人才标签'
                    }, {
                        text: '10万+</br>人才履历'
                    }, {
                        text: '20,000+</br>荣誉奖励'
                    }]
                }, {
                    title: '区域',
                    list: [{
                        text: '78,000+</br>产业园区'
                    }, {
                        text: '50,000+</br>区域政策'
                    }, {
                        text: '长三角、大湾区、珠三角...</br>区域规划',
                        isFullRow: true,
                    }]
                }
            ]
        }
    },
    created() {

    },
    methods: {

    },
}
</script>
<style lang="scss" scoped>
.big-data-page {
    .banner-wrap {
        background-color: #e0edfb;

        .banner {
            width: 100%;
            min-width: $container-width;
            max-width: Translate(1920px);
            height: Translate(500px);
            background: url("https://images.zzt.com.cn/gnW/2024/04/24/bigData-banner.png") no-repeat;
            background-position: center center;
            background-size: cover;
            margin: 0 auto;

            &-box {
                width: $container-width;
                margin: 0 auto;
                position: relative;
                height: 100%;
            }

            &-text {
                text-align: left;

                .title {
                    position: absolute;
                    top: Translate(170px);
                    left: 0;
                    font-size: Translate(40px);
                    font-weight: 500;
                }

                .sub-title {
                    position: absolute;
                    top: Translate(240px);
                    left: 0;
                    font-size: Translate(16px);
                    color: #666;

                    p {
                        height: Translate(30px);
                        line-height: Translate(30px);
                    }
                }
            }
        }
    }

    .interface-service {
        width: 100%;
        height: Translate(540px);
        box-sizing: border-box;
        background: #FFFFFF;

        .card-main {
            display: flex;
            justify-content: space-between;

            .card-item {
                width: Translate(220px);
                height: Translate(290px);
                background: url('https://images.zzt.com.cn/gnW/2024/04/24/interface-service-item-bg.png') no-repeat;
                background-position: center center;
                background-size: cover;

                .card-item-title {
                    font-size: Translate(24px);
                    color: #333333;
                    text-align: center;
                    font-weight: 500;
                    margin-top: Translate(22px);
                    margin-bottom: Translate(18px);
                }

                .introduce-main {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    padding: 0 Translate(14px);

                    .introduce-item {
                        width: Translate(89px);
                        height: Translate(90px);
                        background: #FFFFFF;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-bottom: Translate(14px);

                        p {
                            line-height: Translate(24px);
                            font-size: Translate(16px);
                            color: #333333;
                        }

                    }

                    .full-row {
                        width: Translate(192px);
                    }
                }
            }
        }
    }

    .data-service {
        background: url(https://images.zzt.com.cn/gnW/2024/04/24/data-service-bg.png) no-repeat;
        background-position: center center;
        background-size: cover;
        width: 100%;
        height: Translate(620px);
        box-sizing: border-box;

        .content {
            .card-main {
                display: flex;


                .card-item {
                    width: Translate(384px);
                    height: Translate(290px);
                    background-position: center center;
                    background-size: cover;

                    .card-item-title {
                        text-align: left;
                        font-size: Translate(24px);
                        color: #333333;
                        line-height: 34px;
                        font-weight: 500;
                        margin-top: Translate(83px);
                        margin-left: Translate(34px);
                    }

                    .introduce-main {
                        text-align: left;
                        margin-top: Translate(14px);
                        margin-left: Translate(34px);
                        font-size: Translate(16px);
                        color: #333333;

                        p {
                            height: Translate(32px);
                            line-height: Translate(32px);
                            display: flex;
                            align-items: center;

                            &::before {
                                content: '';
                                display: inline-block;
                                width: Translate(4px);
                                height: Translate(4px);
                                border-radius: Translate(2px);
                                background: #999999;
                                margin-right: Translate(10px);
                            }
                        }
                    }

                    &:nth-child(2) {
                        margin: 0 Translate(24px);
                    }
                }
            }
        }
    }

    .application-scenarios {
        width: 100%;
        height: Translate(700px);
        box-sizing: border-box;
        background: #FFFFFF;

        .card-main {
            display: flex;
            justify-content: space-between;

            .card-item {
                width: Translate(588px);
                height: Translate(404px);
                background-position: center center;
                background-size: cover;
                box-shadow: 0 4px 8px 0 rgba(166, 166, 166, 0.10);

                .card-item-title {
                    font-size: Translate(24px);
                    color: #1D2745;
                    text-align: center;
                    line-height: Translate(34px);
                    font-weight: 500;
                    margin-top: Translate(33px);
                    margin-bottom: Translate(6px);
                }

                .introduce-main {
                    text-align: left;
                    margin-left: Translate(30px);

                    .introduce-item {
                        &:nth-child(1) {
                            height: Translate(102px);
                        }

                        &:nth-child(n+2) {
                            margin-top: Translate(20px);
                        }

                        .introduce-item-title {
                            font-size: Translate(16px);
                            color: #1D2745;
                            font-weight: 500;
                            margin-bottom: Translate(8px);
                        }

                        p {
                            font-size: Translate(14px);
                            color: #687492;
                            font-weight: 500;
                            height: Translate(26px);
                            line-height: Translate(26px);

                            &::before {
                                content: '';
                                display: inline-block;
                                width: Translate(4px);
                                height: Translate(4px);
                                border-radius: Translate(2px);
                                background: #687492;
                                margin-right: Translate(10px);
                            }

                        }
                    }
                }
            }
        }
    }

    .service-consultation {
        width: 100%;
        height: Translate(540px);
        box-sizing: border-box;
        background: #F5F6F9;
    }
}

section {
    padding-top: Translate(74px);

    .content {
        width: $container-width;
        margin: 0 auto;


        .img-container {
            display: flex;
            justify-content: space-between;

            img {
                margin-left: Translate(20px);
            }
        }
    }

    .title {
        font-size: Translate(36px);
        color: #333333;
        font-weight: 500;
        margin: 0 auto Translate(46px);
        min-width: $container-width;
    }
}

.custom-my-btn {
    display: inline-block;
    text-align: center;
    width: Translate(140px);
    height: Translate(36px);
    background-color: #4088eb;
    font-size: Translate(14px);
    color: #fff;
    border-radius: Translate(4px);
    line-height: Translate(36px);
    cursor: pointer;

    &:hover {
        background-color: #337ee5;
    }
}

.mt44 {
    margin-top: Translate(44px);
}
</style>